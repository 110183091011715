import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { Pagination } from '../pagination';
import { LISTINGS_PER_PAGE } from '../../const';
import { SearchBox } from '../search-box/search-box';

export interface MobileToggleControlsProps {
  toggleFilters: () => void;
  toggleList: () => void;
  showList: boolean;
  className?: string;
  totalListings: number;
  listingsStats: any;
  listingsData: any;
  filterValues: any;
  isSearchBoxFocused: boolean;
  setIsSearchBoxFocused: (focused: boolean) => void;
  centerCoordinates: [number, number];
  setCenterCoordinates: (coords: [number, number]) => void;
  getBoundByCenter: any;
  searchByBoundary: any;
  isListVisible: boolean;
  headingText: string;
}

export const MobileToggleControls = ({
  toggleFilters,
  toggleList,
  showList,
  className,
  totalListings,
  listingsStats,
  listingsData,
  filterValues,
  isSearchBoxFocused,
  setIsSearchBoxFocused,
  centerCoordinates,
  setCenterCoordinates,
  getBoundByCenter,
  searchByBoundary,
  isListVisible,
  headingText,
}: MobileToggleControlsProps) => {
  const showMobilePagination = totalListings > LISTINGS_PER_PAGE && !showList;
  return (
    <div className={showMobilePagination ? 'mb-28' : 'mb-20'}>
      <div
        className={twMerge(
          `flex gap-1 pl-4 pr-2 absolute -top-1 md:hidden right-0 w-full justify-between flex-col ${
            !showList ? 'bg-gray-100' : ''
          } z-10 py-3 md:py-0 md:mt-3`,
          className,
        )}
      >
        <div className="flex">
          <div className="h-2 flex-1">
            {isListVisible && (
              <SearchBox
                className={twMerge(
                  'absolute top-3 md:top-3.5 md:w-full flex flex-col md:items-center transition-all duration-500 ease-in-out left-2 md:left-0',
                  `${isSearchBoxFocused ? 'w-full -ml-2' : 'w-24 md:w-full'}`,
                )}
                inputClassName={`${
                  isSearchBoxFocused ? 'w-full' : 'w-12'
                } md:w-3/4`}
                onBlur={() => {
                  setIsSearchBoxFocused(false);
                }}
                onFocus={() => {
                  setIsSearchBoxFocused(true);
                }}
                centerLocation={centerCoordinates}
                handleAreaChange={({ lat, lng }) => {
                  setCenterCoordinates({ lat, lng });
                  const bound = getBoundByCenter({ lat, lng }, 1);
                  return searchByBoundary({
                    _sw: { lng: bound[0], lat: bound[1] },
                    _ne: { lng: bound[2], lat: bound[3] },
                  });
                }}
              />
            )}
          </div>
          <button
            className="mx-0 bg-white active:bg-white shadow-md-dark text-coral-med focus:outline-none border-transparent w-52 rounded-full flex-1 px-12 md:px-0"
            onClick={() => toggleFilters()}
          >
            <span className="font-bold text-base">Filters</span>
          </button>
          <div className="flex-1 flex justify-end">
            <div
              className="overflow-hidden shadow-md-dark rounded-full flex cursor-pointer font-bold text-base z-10"
              onClick={() => toggleList()}
            >
              <div
                className={`flex-1 px-4 py-2 bg-white active:bg-white shadow-md-dark text-coral-med focus:outline-none border-transparent`}
              >
                {showList ? 'List' : 'Map'}
              </div>
            </div>
          </div>
        </div>

        {showMobilePagination && (
          <Pagination
            listingsStats={listingsStats}
            listingsData={listingsData}
            filterValues={filterValues}
          />
        )}
        {!showList && (
          <h1 className={`mt-2 text-center font-semibold`}>{headingText}</h1>
        )}
      </div>
    </div>
  );
};
