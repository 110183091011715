import { Icon, Link } from '@requity-homes/component-library';

import { capitalizeAllFirstLetters } from '@requity-homes/utils';
import { useRouter } from 'next/router';

interface BreadcrumbNavigationProps {
  province: string;
  streetName?: string;
}

export const BreadcrumbNavigation = ({
  province,
  streetName,
}: BreadcrumbNavigationProps) => {
  const router = useRouter();
  const city = (router.query.city as string) || '';
  return (
    <div className="my-2 hidden md:block">
      <Link
        className="text-gray-600 cursor-pointer no-underline hover:text-gray-600"
        href="https://requityhomes.com"
        target="_blank"
      >
        Home <Icon glyph="chevronRight" className="w-3" />{' '}
      </Link>
      <Link
        className="text-gray-600 cursor-pointer no-underline hover:text-gray-600"
        href="/"
      >
        Rent-to-Own Listings <Icon glyph="chevronRight" className="w-3" />{' '}
      </Link>
      {!streetName ? (
        <Link href="#" className="text-black no-underline hover:text-black">
          {capitalizeAllFirstLetters(city.replace(/-/g, ' '))}, {province}
        </Link>
      ) : (
        <Link
          className="text-gray-600 cursor-pointer no-underline hover:text-gray-600"
          href={`/${router.query.province}/${router.query.city}/rent-to-own`}
        >
          {capitalizeAllFirstLetters(city.replace(/-/g, ' '))}, {province}
        </Link>
      )}
      {streetName && (
        <Link href="#" className="text-black no-underline hover:text-black">
          {' '}
          <Icon glyph="chevronRight" className="w-3 text-gray-600" />{' '}
          {streetName}
        </Link>
      )}
    </div>
  );
};
