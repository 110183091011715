import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import Image from 'next/image';

/* eslint-disable-next-line */
export interface ImageSwiperProps {
  images: string[];
  alt: string;
}

export const ImageSwiper = (props: ImageSwiperProps) => {
  const { images = [], alt } = props;
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <>
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2 -mb-2 md:mb-0"
      >
        {images.map((url) => (
          <SwiperSlide key={url}>
            <img src={url} alt={alt} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={3}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {images.map((url) => (
          <SwiperSlide key={url}>
            <img src={url} alt={alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
